import * as React from "react";
import ReactMarkdown from "react-markdown";

import "./markdown.css";
import Layout from "../components/Layout/Layout";
import { GET_INFOS_BY_LABEL } from "../query/getInfos";
import { injectIntl } from "gatsby-plugin-intl";
import { useQuery } from "@apollo/client";

// @ts-ignore
const publicCondition = ({ intl }) => {
  const { loading, error, data } = useQuery(GET_INFOS_BY_LABEL, {
    variables: {
      label: "franchaising",
      locale: intl.locale,
    },
  });

  if (loading) return intl.formatMessage({ id: "Loading.LoadingTitle" });

  if (error) return intl.formatMessage({ id: "Error.ErrorTitle" });

  return (
    <Layout>
      <div className="grid  lg:justify-center sm:justify-left xs:justify-center  bg-[#FAFAFA]  ">
        <div className="flex justify-center items-center  xl:max-w-[1200px] xl:w-[1200px] lg:max-w-[1000px] lg:w-[1000px] sm:max-w-[680px] sm:w-[680px] xs:max-w-[300px] xs:w-[300px] ">
          <div className="xl:w-full mb-10 mt-5 flex flex-col   px-2  markdown">
            <ReactMarkdown>
              {data && data.infos && data.infos.data.length
                ? data.infos.data[0].attributes.info
                : intl.formatMessage({
                    id: "FranchaisingPage",
                  })}
            </ReactMarkdown>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default injectIntl(publicCondition);
